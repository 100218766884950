import "react-clock/dist/Clock.css";
import "../styles/error.css";
import { useState } from "react";

export default function Error() {
  // const errorPages = [
  //   "sygnal",
  //   "grid",
  //   // "schiavaTurca"
  // ];
  // const [errorPage, setErrorPage] = useState(
  //   errorPages[parseInt(Math.random() * errorPages.length)]
  // );

  return (
    <section div className="error">
      {/* {errorPage === "sygnal" ? ( */}
      <div className="sygnal">
        <div className="cont">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="cont">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1> error 404</h1>
      </div>
      {/* ) : null}
      {errorPage === "grid" && (
        <div className="gridContainer">
          <div className="grid">
            <h1
              onClick={() => {
                window.location.reload();
              }}
            >
              error 404
            </h1>
          </div>
        </div>
      )}

      {errorPage === "schiavaTurca" && (
        <img src="assets/imgs/schiavaTurca.webp" />
      )} */}
    </section>
  );
}
