import React from "react";
import Posts from "./Posts";

export default function PostSection() {
  return (
    <>
      <div className="container" id="selected">
        <section>
          <hr />
          <p className="fixedP">
            {/* Interno 9
            <br /> */}
            Software and web developement, computer graphics and UI design.
            <br />
            Based in Switzerland.
            <br />
            <br />
            <a
              style={{
                color: "#cacaca",
              }}
              href="mailto:nicolajurifontana@gmail.com"
            >
              contact
            </a>
          </p>
        </section>

        <section id="info">
          <hr />
          <Posts />
        </section>
      </div>
    </>
  );
}
