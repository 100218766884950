import Swipe from "./Swipe";

export default function Post(props) {
  return (
    <div
      className=""
      style={{
        marginBottom: "12em",
      }}
    >
      <Swipe imgs={props.imgs} />
      <a
        style={{
          color: "#000000",
          marginLeft: "1em",
        }}
        href={props.url}
        target="_blank"
      >
        {props.urlClean}
      </a>
    </div>
  );
}
