import React, { useState, useEffect } from "react";

export default function Home() {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const imageContext = require.context(
      "../../public/assets/imgs/noahdillon/",
      false,
      /\.(png|jpe?g|gif|webp|svg)$/
    );
    const imagePaths = imageContext
      .keys()
      .map((key) => `/assets/imgs/noahdillon${key.slice(1)}`);
    setImages(imagePaths);
  }, []);

  useEffect(() => {
    if (images.length === 0) return;
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 400);
    return () => clearInterval(interval);
  }, [images]);

  return (
    <div
      style={{
        marginBottom: "12em",
      }}
    >
      {images.length > 0 && (
        <img
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            aspectRatio: "3/2",
          }}
        />
      )}

      <a
        style={{
          marginLeft: "1em",
        }}
        href="https://www.noahpdillon.com"
        target="_blank"
      >
        noahpdillon.com
      </a>
    </div>
  );
}
